@import "../../../styles/colors/_common.scss";

.inputContainer {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  width: 100%;
  margin-bottom: 20px;
}

.inputWrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.widgetWrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.widgetWrapper > div {
  width: 100%;
}

.labelContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 5px;
}

.label {
  font-family: Montserrat;
  font-weight: bold;
  font-size: 12px;
  color: #7d8d91;
  letter-spacing: 0;
}

.secondaryLink {
  font-size: 14px;
  color: $primaryColor;
  cursor: pointer;
}

.secondaryLink:active {
  color: #0064b7;
}

.inputField {
  box-sizing: border-box;
  width: 100%;
  height: 48px;
  border-radius: 4px;
  border: solid 1px #e0e0e0;
  background-color: #ffffff;
  padding: 7.5px;
  font-size: 16px;
  color: #627d98;
  -webkit-text-fill-color: #627d98;
  -webkit-opacity: 1;
}

.widgetFieldDropDown {
  box-sizing: border-box;
  height: 40px;
  border-radius: 4px;
  border: solid 1px;
  background-color: #ffffff;
  padding: 13px;
  font-size: 16px;
  color: #627d98 !important;
}

.widgetFieldDropDown > div {
  font-size: 16px;
  // color: #627d98 !important;
  color: #647e97 !important;
}

::placeholder {
  color: #c6cbcd;
}

.labelDetail {
  color: #d2dcde;
}

.error {
  color: red;
  // margin-bottom: 3px;
  font-size: 12px;
  padding-left: 3px;
  font-family: Montserrat;
  font-weight: bold;
}

.warn {
  color: orange;
  margin-top: 7px;
  margin-bottom: 3px;
  font-size: 12px;
}

.semi-tiny {
  margin-left: 20px;
  width: 10%;
}
.tiny {
  width: 15.3%;
}
.tiny-with-margin {
  margin-left: 20px;
  width: 10%;
}
.small-with-margin {
  width: 25%;
  margin-left: 20px;
}

.semi-small {
  width: 100%;
}
.semi-small2 {
  width: 23%;
}

.small {
  width: 25%;
}
.small-with-margin {
  width: 25%;
  margin-left: 20px;
}
//media query controlled
.semi-medium {
  width: 100%;
}
.medium-with-margin {
  width: 100%;
  margin-left: 0px;
}
.medium {
  width: 100%;
}
//media query controlled

.mediumPlus {
  width: 70%;
}

.large {
  width: 80.5%;
}

.semiLarge {
  width: 97%;
}

.fullWidth {
  width: 100%;
}

.leftIcon {
  position: absolute;
  padding-left: 10px;
  padding-right: 10px;
}

.leftIconReferenceStyles {
  padding-left: 35px;
}

.rightText {
  position: absolute;
  right: 0;
  padding-right: 10px;
  cursor: pointer;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #38a6f4;
}

.notEditableStyles {
  background-color: #f7f8f8 !important;
  border: none;
}

.imageStyles {
  position: absolute;
  width: 25px;
  height: 25px;
  top: 15px;
  right: 15px;
  cursor: pointer;
}

.withCheckBox {
  margin-bottom: 0px;
}

.coreSmallContainer {
  justify-content: center;
  border-radius: 2px;
  margin-bottom: 0px;
}

.coreSmallContainerDropdown {
  justify-content: center;
  height: 38px !important;
  border-radius: 2px;
  margin-bottom: 0px;
  font-size: 14px;
}

.coreSmall {
  font-family: Montserrat;
  font-size: 14px;
  color: #4d596d;
  letter-spacing: 0;
  line-height: 40px;
  // background-color: #f1f4f7;
  background-color: #ffffff;
  border: none;
  border: 1px solid #e0e5ea;
  border-radius: 2px;
}

.coreSmallDropdown {
  padding: 0px !important;
  height: 33px !important;
  font-family: Montserrat;
  font-size: 14px;
  color: #4d596d;
  letter-spacing: 0;
  line-height: 33px;
  // background-color: #f1f4f7;
  background-color: #ffffff;
  border: none;
  border: 1px solid #e0e5ea;
  border-radius: 2px;
}

.coreSmallDropdown > div {
  font-size: 14px !important;
}

.coreSmallWhite {
  height: 33px;
  font-family: Montserrat;
  font-size: 14px;
  color: #4d596d;
  letter-spacing: 0;
  line-height: 40px;
  background-color: #ffffff;
  border: none;
  border: 1px solid #e0e5ea;
  border-radius: 2px;
}
.coreSmallPlain {
  height: 33px;
  font-family: Montserrat;
  font-size: 14px;
  color: #4d596d;
  letter-spacing: 0;
  line-height: 40px;
  background-color: transparent;
  border: none;
  border: 1px solid #e0e5ea;
  border-radius: 2px;
}

.tooltip {
  padding-left: 0.2rem;
}

.tooltipImage {
  max-width: 280px;
  margin: 0 auto;
}

.borderBox {
  box-sizing: border-box;
}

.tableInput {
  height: 1rem;
}

.superTiny {
  height: 30px;
  width: 70px;
  font-size: 12px;
  padding: 0.5rem;
}

@media screen and (min-width: 600px) {
  .medium {
    width: 50%;
  }
  .semi-medium {
    width: 30%;
  }
  .medium-with-margin {
    width: 30%;
    margin-left: 20px;
  }
  .semi-small {
    width: 20%;
  }
}

@media screen and (max-width: 600px) {
  .coreSmall {
    width: 100%;
  }

  .coreSmallContainer {
    width: 100%;
  }

  .coreSmallContainerDropdown {
    width: 100%;
  }
}
