@import "../../../styles/colors/_common.scss";

.inputContainer {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  margin-bottom: 20px;
}

.inputWrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.labelContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 5px;
}

.label {
  font-family: Helvetica-Bold;
  font-size: 12px;
  color: #7d8d91;
  letter-spacing: 0;
}

.secondaryLink {
  font-size: 14px;
  color: $primaryColor;
  cursor: pointer;
}

.secondaryLink:active {
  color: #0064b7;
}

.inputField {
  box-sizing: border-box;
  width: 100%;
  height: 52px;
  border-radius: 4px;
  border: solid 1px #e0e0e0;
  background-color: #ffffff;
  padding: 0 7.5px;
  font-size: 16px;
  color: #627d98;
}

::placeholder {
  color: #c6cbcd;
}

.labelDetail {
  color: #d2dcde;
}

.error {
  color: red;
  margin-top: 7px;
  margin-bottom: 3px;
  font-size: 12px;
}

.semi-tiny {
  margin-left: 20px;
  width: 10%;
}
.tiny {
  width: 15.3%;
}
.tiny-with-margin {
  margin-left: 20px;
  width: 10%;
}
.small-with-margin {
  width: 25%;
  margin-left: 20px;
}

.semi-small {
  width: 20%;
}
.semi-small2 {
  width: 23%;
}

.small {
  width: 25%;
}
.small-with-margin {
  width: 25%;
  margin-left: 20px;
}
.semi-medium {
  width: 30%;
}
.medium-with-margin {
  width: 30%;
  margin-left: 20px;
}
.medium {
  width: 50%;
}
.mediumPlus {
  width: 70%;
}
.large {
  width: 80.5%;
}
.semiLarge {
  width: 84.3%;
}

.fullWidth {
  width: 100%;
}

.leftIcon {
  position: absolute;
  padding-left: 10px;
  padding-right: 10px;
}

.leftIconReferenceStyles {
  padding-left: 35px;
}

.rightText {
  position: absolute;
  right: 0;
  padding-right: 10px;
  cursor: pointer;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #38a6f4;
}

.notEditableStyles {
  background-color: #f7f8f8 !important;
}

.imageStyles {
  position: absolute;
  width: 25px;
  height: 25px;
  top: 15px;
  right: 15px;
  cursor: pointer;
}

.withCheckBox {
  margin-bottom: 0px;
}

.coreSmallContainer {
  justify-content: center;
  height: 38px;
  border-radius: 2px;
  margin-bottom: 0px;
}

.coreSmall {
  height: 33px;
  font-family: Helvetica;
  font-size: 14px;
  color: #4d596d;
  letter-spacing: 0;
  line-height: 40px;
  background-color: #f1f4f7;
  border: none;
  border: 1px solid #e0e5ea;
  border-radius: 2px;
}

.coreSmallWhite {
  height: 33px;
  font-family: Helvetica;
  font-size: 14px;
  color: #4d596d;
  letter-spacing: 0;
  line-height: 40px;
  background-color: #ffffff;
  border: none;
  border: 1px solid #e0e5ea;
  border-radius: 2px;
}

.employeeDetails {
  border: 1px solid rgb(216, 222, 226);
  color: #627d98;
  border-radius: 5px;
  padding: .75rem;
  width: 100%;
}

@media screen and (max-width: 600px) {
  .inputContainer {
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
	.inputContainer {
		width: 100%;
	}
}