.selectContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 100%;
  min-width: 100px;
  margin-bottom: 20px;
}

.customSelect {
  width: 100%;
  border-radius: 5px;
  position: relative;
  background-color: #ffffff;
  box-sizing: border-box;
}

.customSelectThin .customSelect {
  width: 55%;
}

.selectLabel {
  font-family: Helvetica;
  font-weight: bold;
  font-size: 12px;
  color: #7d8d91;
  letter-spacing: 0;
  margin-bottom: 5px;
}

.dropDownArrow {
  position: absolute;
  width: 100%;
  width: 25px;
  height: 25px;
}

.large {
  width: 95%;
}
.largeWithoutMarginTop {
  width: 95%;
  margin-top: 0px;
}

.medium {
  width: 70%;
}

small {
  width: 50%;
}

.manualWidthInbox {
  width: 105px;
  margin-top: 20px;
}

.medium-with-margin {
  width: 100%;
  margin-left: 0px;
  height: 47px;
}

.coreSmallContainer {
  justify-content: center;
  height: 38px;
  border-radius: 2px;
  margin-bottom: 0px;
}

.coreSmall {
  height: 33px;
  font-family: Helvetica;
  font-size: 14px;
  color: #4d596d;
  letter-spacing: 0;
  line-height: 40px;
  background-color: #f1f4f7;
  border: none;
  border: 1px solid #e0e5ea;
  border-radius: 2px;
}
.coreSmallPlain {
  height: 33px;
  font-family: Helvetica;
  font-size: 14px;
  color: #4d596d;
  letter-spacing: 0;
  line-height: 40px;
  background-color: transparent;
  border: none;
  border: 1px solid #e0e5ea;
  border-radius: 2px;
}

@media screen and (min-width: 600px) {
  .medium-with-margin {
    width: 50%;
    margin-left: 20px;
    height: 47px;
  }
}
