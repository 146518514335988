.stepper {
  position: relative;
  display: table;
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  cursor: default;
}

.cursorPointer {
  cursor: pointer;
}

.stepper__step {
  position: relative;
  display: table-cell;
  text-align: center;
  padding: 0.7rem;
}

.stepper__indicator {
  position: relative;
  display: block;
  z-index: 2;
}

.stepper__label {
  font-family: "Montserrat";
  position: relative;
  display: none;
  margin: 15px 0;
  color: #6b7280;
  z-index: 2;
  font-size: 12px;
}

.stepper__info {
  position: relative;
  display: inline-block;
  width: 2.5rem;
  height: 2.5rem;
  border: 1px solid #dee5e6;
  border-radius: 50%;
  background-color: #ffffff;
  font-size: 1rem;
  line-height: 2.5rem;
  text-align: center;
  color: #9ca3af;
  z-index: 2;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1), 0 4px 4px rgba(0, 0, 0, 0.1),
    0 8px 8px rgba(0, 0, 0, 0.1), 0 12px 12px rgba(0, 0, 0, 0.1);
}

.stepper__panel {
  display: none;
}

.stepper .stepper__step:after {
  content: " ";
  position: absolute;
  left: 50%;
  top: 1.75rem;
  width: 100%;
  height: 0.125rem;
  background-color: #e3e8ec;
  z-index: 1;
}
.stepper .stepper__step:last-child:after {
  display: none;
}

.stepper__inline {
  display: flex;
  justify-content: space-between;
}

.stepper__inline .stepper__step {
  padding: 0.2rem;
  margin-right: 0.2rem;
}

.stepper__inline .stepper__step:after {
  width: 0;
}

.stepper__vertical .stepper__step {
  display: block;
  text-align: left;
}
.stepper__inline .stepper__step {
  display: inline-flex;
  text-align: left;
}

.stepper__inline .stepper__label {
  padding-left: 0.6rem;
  margin-top: 0.6rem;
}

.stepper__vertical .stepper__label {
  padding-left: 1rem;
}

.stepper__vertical .stepper__indicator,
.stepper__vertical .stepper__label {
  display: table-cell;
}

.stepper__vertical .stepper__panel {
  margin-left: 3.5rem;
}

.stepper__vertical .stepper__step:after {
  content: " ";
  position: absolute;
  left: 1.75rem;
  top: 2.5rem;
  bottom: -0.5rem;
  width: 0;
  height: auto;
  border-left: 0.125rem solid #e3e8ec;
}

.stepper .stepper__step.is-complete .stepper__info {
  border-color: #a6b6c3;
  background-color: #a6b6c3;
}
.stepper .stepper__step.is-complete .stepper__label {
  color: #6b7280;
}

.stepper .stepper__step.is-active .stepper__info {
  border-color: #627c90;
  background-color: #627c90;
}
.stepper .stepper__step.is-active .stepper__label {
  color: #6b7280;
  margin: 15px 0;
}

.stepper .stepper__step.is-warning .stepper__info {
  border-color: #f1c40f;
  background-color: #f1c40f;
}
.stepper .stepper__step.is-warning .stepper__label {
  color: #f1c40f;
}

.stepper .stepper__step.is-error .stepper__info {
  border-color: #e95a4b;
  background-color: #e95a4b;
}
.stepper .stepper__step.is-error .stepper__label {
  color: #e95a4b;
}

.active {
  background-color: #38a6f4;
  color: #ffffff;
  border: 0px;
}

.activeLabel {
  color: #7d8d91;
  margin: 15px 0;
}

.completed {
  display: inline-block;
  // width: 1.45rem;
  // height: 1.45rem;
  width: 100%;
  height: 100%;
  color: transparent;
  z-index: 2;
  background-color: transparent;
}

.stepper__inline .stepper__label {
  padding-left: 1rem;
}

.completedSibling {
  padding: 0rem !important;
}

@media screen and (min-width: 600px) {
  .stepper__label {
    font-family: "Montserrat";
    position: relative;
    display: block;
    margin: 15px 0;
    color: #6b7280;
    z-index: 2;
    font-size: 11px;
  }
}
