.cardList {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.cardList > :last-child {
  margin-right: 0px !important;
}

.card {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 30px;
  border-radius: 10px;
  background-color: #ffffff;
  padding: 10px;
  font-size: 16px;
  margin-bottom: 20px;
  color: #7d8d91;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  margin-right: 20px;
  cursor: pointer;
  border: 1px solid #ebf0f4;
  box-sizing: content-box;
}
.cardOne {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 30px;
  border-radius: 4px;
  background-color: #ffffff;
  padding: 10px;
  font-size: 16px;
  margin-bottom: 20px;
  color: #7d8d91;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  margin-right: 20px;
  cursor: pointer;
}

.cardListLeft {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.active {
  border: solid 2px #38a6f4;
}

.label {
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #7d8d91;
  letter-spacing: 0;
  padding-bottom: 3px;
}
.labelOne {
  font-family: "Montserrat";
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #7d8d91;
  opacity: 0.6;
  letter-spacing: 0;
  padding-bottom: 3px;
}

.subLabel {
  font-family: "Montserrat";
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #7d8d91;
  opacity: 0.6;
  letter-spacing: 0;
}
.subLabelOne {
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #7d8d91;
  letter-spacing: 0;
}

.smallFixed {
  flex: initial;
  margin-right: 5%;
  width: 20%;
}

.cardListLeft > :last-child {
  margin-right: 0px !important;
}

.disabled {
  cursor: not-allowed;
}

.noShadow {
  box-shadow: 0 0px 0px 0;
  cursor: default;
}

.notEditableStyles {
  background-color: #f7f8f8 !important;
}
