.container {
	width: 100%;
	margin-top: 10px;
	margin-bottom: 10px;
}

.labelContainer {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
}

.label {
	font-family: Helvetica;
	font-weight: bold;
	font-size: 12px;
	color: #7d8d91;
	letter-spacing: 0;
	margin-bottom: 5px;
}

.labelDetail {
	font-family: Helvetica;
	font-weight: bold;
	font-size: 12px;
	color: #d2dcde;
	letter-spacing: 0;
	margin-bottom: 5px;
	margin-left: 5px;
}

.contentContainer {
	position: relative;
	width: 100%;
	box-sizing: border-box;
	height: 48px;
	border-radius: 4px;
	// border: solid 1px #e0e0e0;
	background-color: #f7f8f8;
	padding: 15px;
}

.content {
	font-size: 16px;
	color: #627d98;
}

.rightText {
	position: absolute;
	right: 0;
	top: 30%;
	padding-right: 10px;
	cursor: pointer;
	font-size: 14px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.5;
	letter-spacing: normal;
	color: #38a6f4;
}
