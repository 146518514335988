$tooltip-color: #fff;
$tooltip-text-color: #4d596d;

.tooltip {
  position: relative;
}

.tooltipTrigger {
  display: inline-block;
  text-decoration: none;
}

.tooltipBubble {
  min-width: 120px;
  max-width: 300px;
  position: absolute;
  z-index: 10;
  border: 0.1em solid lighten($tooltip-text-color, 40%);
  border-radius: 2px;
  &::after {
    content: "";
    position: absolute;
  }
}

.tooltip-top {
  bottom: 100%;
  left: 50%;
  padding-bottom: 9px;
  transform: translateX(-50%);

  &::after {
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    // border-top: 9px solid $tooltip-color;
    border-top: 9px solid $tooltip-text-color;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

.tooltip-bottom {
  top: 100%;
  left: 50%;
  padding-top: 9px;
  transform: translateX(-50%);

  &::after {
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-bottom: 9px solid $tooltip-color;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

.tooltip-left {
  top: 50%;
  right: 100%;
  padding-right: 9px;
  transform: translateY(-50%);

  &::after {
    border-left: 9px solid $tooltip-color;
    border-top: 9px solid transparent;
    border-bottom: 9px solid transparent;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
}

.tooltip-right {
  top: 50%;
  left: 100%;
  padding-left: 9px;
  transform: translateY(-50%);

  &::after {
    border-right: 9px solid $tooltip-color;
    border-top: 9px solid transparent;
    border-bottom: 9px solid transparent;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
}

.tooltipMessage {
  font-family: "Montserrat";
  background: $tooltip-color;
  border-radius: 3px;
  color: $tooltip-text-color;
  font-size: 0.75rem;
  line-height: 1.4;
  padding: 0.75em;
  text-align: center;
}
