body {
  background-color: #f5f7f8;
}
.container {
  background-color: #f5f7f8;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  letter-spacing: 0.3px;
}

.innerContent {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top: solid 1px #e2e2e2;
  background-color: #fff;
  color: #7d8d91;
  font-size: 12px;
  font-weight: normal;
  width: 100%;
  height: 100%;
}

.dashboardContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
  padding-bottom: 50px;
}

.xx {
  display: flex;
}
.contentContainer {
  display: flex;
  align-self: center;
  flex-direction: column;
  width: 600px;
  padding: 40px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}
.yy {
  display: flex;
}
.contentContainerX {
  display: flex;
  align-self: center;
  flex-direction: column;
  width: 600px;
  padding: 40px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  margin-bottom: 4rem;
}
.asdsa {
  display: flex;
}
.subHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* flex: 1; */
  margin-top: 70px;
}

.leftSubHeader {
  display: flex;
}

.centerSubHeader {
  display: flex;
  width: 500px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 90px;
}

.largeHeader {
  display: block;
  color: #3b3f40;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 5px;
  font-family: "Montserrat";
}
.smallHeader {
  display: block;
  color: #6b7280;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
  margin-top: 5px;
}

.description {
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #6b7280 !important;
}

.descriptionSucccess {
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.8;
  letter-spacing: normal;
  justify-content: center;
  text-align: center;
  color: #6b7280;
}

.altActionLink {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: #949fa6;
  padding: 3px;
  cursor: pointer;
  z-index: 2;
}
.customPhotoInput {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  z-index: 1;
  cursor: pointer;
}

.description > a {
  color: #1eb6ea;
  cursor: pointer;
}

.description > b {
  cursor: pointer;
}

.section {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  margin-bottom: 15px;
}

.sectionNoMargin {
  display: flex;
  flex-direction: column;
}

.sectionContent {
  margin-top: 20px;
}

.sectionTitle {
  padding: 12px 0;
  margin: 12px 0;
  font-family: "Montserrat";
  font-weight: bolder !important;
  font-size: 18px;
  color: #6673a5;
  letter-spacing: 0;
}

.pageTitle {
  padding: 12px 0;
  margin: 12px 0;
  margin-top: 0px;
  padding-top: 0px;
  font-family: "Montserrat";
  font-weight: "bold";
  font-size: 20px;
  color: #3b3f40;
  letter-spacing: 0;
  line-height: 24px;
}

.sectionTitleNoPaddingTop {
  font-family: "Montserrat";
  font-weight: "bold" !important;
  padding: 12px 0;
  margin: 12px 0;
  font-size: 16px;
  color: #6673a5;
  letter-spacing: 0;
  padding-top: 0px;
  margin-top: 0px;
}

.inputContainer {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
}

.inputLabelContainer {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: left;
  width: 100%;
  color: #7d8d91;
}

.inputLabel {
  display: flex;
  flex-direction: row;
  justify-content: left;
  margin-bottom: 2px;
  width: 50%;
}

.multiInputContainer {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
}
.multiInputContainer2 {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
}
.multiInputContainerLeft {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-start;
}

.leftInput {
  display: flex;
  flex: 5;
}

.centerInput {
  display: flex;
  flex: 1;
}

.rightInput {
  display: flex;
  flex: 1;
}

.textCardContainer {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.textCardContainer > :last-child {
  margin-right: 0px !important;
}

.languageContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1;
}

.leftLanguage {
  max-width: 110px;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 30px;
  border-radius: 4px;
  background-color: #ffffff;
  padding: 10px;
  font-size: 16px;
  margin-bottom: 20px;
  color: #7d8d91;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.08);
  border: solid 2px #2d42ff;
}
.centerLanguage {
  max-width: 110px;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 30px;
  border-radius: 4px;
  border: solid 1px #e0e0e0;
  background-color: #ffffff;
  padding: 10px;
  font-size: 16px;
  margin-bottom: 20px;
  color: #d2dcde;
}
.rightLanguage {
  max-width: 110px;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 30px;
  border-radius: 4px;
  border: solid 1px #e0e0e0;
  background-color: #ffffff;
  padding: 10px;
  font-size: 16px;
  margin-bottom: 20px;
  color: #d2dcde;
}

.languageInfo {
  font-size: 12px;
}

.uploadLogoContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background: #f7f8f8;
  border-radius: 4px;
  width: 50%;
  padding: 20px;
  margin-top: 20px;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  border-radius: 40px;
  border: solid 1px #dee5e6;
  background-color: #ffffff;
  font-size: 24px;
  color: #7d8d91;
}

.logoTitle {
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 20px;
  font-size: 14px;
  color: #7d8d91;
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.businessesLogo {
  display: flex;
  align-items: center;
  align-self: center;
  width: 60px;
  height: 60px;
}

.actionButton {
  height: 52px;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
}

.altActionButton {
  height: 52px;
  width: 20%;
  display: flex;
  flex-direction: column;
  margin-right: 5px;
  align-items: stretch;
}

.successMessage {
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  color: #3b3f40;
}

.successHeaderContainer {
  padding: 2.5rem 0;
}

.mainInfoSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  border: solid 1px #e2e2e2;
  background-color: #ffffff;
  margin-bottom: 30px;
  padding: 20px;
  border-radius: 20px;
}

.companyLogo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 2px 0 rgba(0, 0, 0, 0.2);
  border: solid 1px #e2e2e2;
  background-color: #ffffff;
  width: 60px;
  height: 60px;
  border-radius: 30px;
}

.bhInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  color: #7d8d91;
  padding: 0 20px;
}
.standAloneLabelInfo {
  display: block;
  font-family: "Montserrat";
  font-size: 14px;
  color: #7d8d91;
  margin-bottom: 15px;
}

.label {
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 12px;
  border: solid 1px #e2e2e2;
  background-color: #f5f7f8;
}

.bold {
  display: flex;
  font-weight: bold;
  color: black;
  font-size: 15px;
  margin-bottom: 3px;
}

.light {
  margin-bottom: 3px;
}

.successImageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.successImage {
  width: 50px;
  height: 50px;
}
.sadz {
  display: flex;
}

.footerSection {
  display: flex;
  flex-direction: column;
  background-color: rgba(246, 248, 249, 0.8);
  padding: 20px 40px;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: #e2e2e2;
  padding-top: 40px;
  padding-bottom: 40px;
}

.sadx {
  display: flex;
}
.contentContainer2 {
  display: flex;
  align-self: center;
  flex-direction: column;
  align-items: stretch;
  width: 600px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
}
.sady {
  display: flex;
}
.mainContentContainer {
  display: flex;
  align-self: center;
  flex-direction: column;
  background-color: #ffffff;
  padding: 40px;
}

.standaloneLabelContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 2px;
}

.standaloneLabel {
  font-family: "Montserrat";
  font-weight: "bold";
  font-size: 14px;
  color: #6b7280 !important;
  margin-bottom: 10px;
  margin-top: 10px;
}

.standaloneLabelDollar {
  font-family: "Montserrat";
  font-weight: "bold";
  font-size: 16px;
  color: #6b7280 !important;
  height: 48px;
  line-height: 1.15;
  align-content: center;
  margin-right: -10px;
}

.standAloneInfoTextLabel {
  font-family: "Montserrat";
  font-size: 16px;
  color: #6b7280 !important;
  line-height: 30px;
  font-weight: 500;
}

.infoTextLabel {
  font-family: "Montserrat";
  font-size: 16px;
  color: #6b7280 !important;
  line-height: 20px;
  font-weight: 500;
}

.infoTextLabelSmaller {
  font-family: "Montserrat";
  font-weight: "bold" !important;
  font-size: 14px;
  color: #6b7280 !important;
  line-height: 20px;
}

.superSubHeader {
  font-family: "Montserrat";
  font-size: 14px;
  color: #4b5563 !important;
  line-height: 20px;
  font-weight: bolder;
  padding: 5px;
  border-left: solid 1px #e2e2e2;
  border-radius: 5px;
  background-image: none, linear-gradient(to right, #d1d5db 30%, #ffffff 100%);
  margin-top: 20px;
}

.inputLabelStylingCopy {
  font-family: "Montserrat";
  font-size: 12px;
  color: #7d8d91 !important;
  font-weight: bold;
  margin-bottom: 5px;
  letter-spacing: "0px";
}

.msgContainer {
  font-family: "Montserrat";
  font-size: 14px;
  color: #6b7280 !important;
  line-height: 25px;
  font-weight: bold;
  padding: 10px;
  border: solid 1px #d1d5db;
  border-radius: 5px;
  background-image: none, linear-gradient(to right, #e5e7eb 20%, #e5e7eb 100%);
  margin-top: 10px;
  margin-bottom: 10px;
  box-shadow: 2px 5px 5px #d1d5db;
}

.subHeaderLabels {
  border-left: solid 1px #e2e2e2;
  background-image: none,
    linear-gradient(to right, #a131c7 30%, #e300b1 60%, #ffffff 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 10px 10px #e3c7ec;
  padding: 7px;
  font-size: 16px;
  color: #ffffff;
  font-weight: bold;
  margin-bottom: 40px;
  margin-top: 20px;
  border-radius: 5px;
}

.buttonAreaContainer {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.headingContainer {
  margin-bottom: 15px;
  margin-top: 10px;
  border: solid 1px #e2e2e2;
  padding: 20px;
  border-radius: 10px;
  background-color: #f9fafb;
  box-shadow: 2px 10px 10px #e5e7eb;
}

.blueContainer {
  width: 95%;
  padding: 15px;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.08);
  background: #ffffff;
  border: 1px solid #d0d6d7;
  margin: 15px 0;
}

.blueArea {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #f4f8ff;
}

.checkboxDropdownContainer {
  width: 100%;
  margin-bottom: 20px;
  padding: 15px;
  padding-left: 20px;
  padding-bottom: 15px;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.08);
  background: #ffffff;
  border: 1px solid #d0d6d7;
  margin: 15px 0;
}

.checkboxDropdownContainerDark {
  margin-top: 30px;
  margin-bottom: 20px;
  padding: 15px;
  padding-left: 15px;
  padding-bottom: 15px;
  border-radius: 4px;
  background: #f7f8f8;
}

.checkboxActiveFix {
  width: 95%;
  box-shadow: none;
  border: none;
}

.checkboxDropDownActive {
  border: solid 0.5px #2d42ff;
  background-color: #f3faff;
  margin: 15px auto;
}
.checkboxActive {
  border: solid 2px #e5e7eb;
  background-color: #f9fafb;
  margin: 15px auto;
  width: 100%;
}
.checkboxCommonActive {
  border: solid 0.5px #2d42ff;
  background-color: #f3faff;
  margin: 15px auto;
}
.checkboxSubmissionActive {
  border: solid 0.5px #2d42ff;
  background-color: #f3faff;
  margin: 15px auto;
}
.checkboxPreferredActive {
  border: solid 0.5px #2d42ff;
  background-color: #f3faff;
  margin: 15px auto;
}
.checkboxExecutiveActive {
  border: solid 0.5px #2d42ff;
  background-color: #f3faff;
  margin: 15px auto;
}
.checkboxManualActive {
  border: solid 0.5px #2d42ff;
  background-color: #f3faff;
  margin: 15px auto;
}
.checkboxDependentsActive {
  border: solid 0.5px #2d42ff;
  background-color: #f3faff;
  margin: 15px auto;
}
.checkboxCanadaAddressesActive {
  border: solid 0.5px #2d42ff;
  background-color: #f3faff;
  margin: 15px auto;
}
.checkboxOtherAddressesActive {
  border: solid 0.5px #2d42ff;
  background-color: #f3faff;
  margin: 15px auto;
}
.checkboxPubliclyTradedActive {
  border: solid 0.5px #2d42ff;
  background-color: #f3faff;
  margin: 15px auto;
}

.actionLink {
  height: 52px;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  cursor: pointer;
}

.actionLinkText {
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #7d8d91;
}

.addAdministrator {
  display: block;
  text-decoration: none;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #2d42ff;
  padding-top: 15px;
  padding-bottom: 15px;
  text-align: center;
  border-radius: 4px;
  border: 0.5px dashed #dcdddf;
}
.removeAdministratorWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.removeAdministrator {
  text-decoration: none;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: red;
  padding-top: 10px;
  padding-bottom: 20px;
}

.administratorWrapper {
  margin-top: 50px;
}

.rightCheckboxGroupInput {
  padding-left: 45px;
}

.closeIcon {
  cursor: pointer;
  margin-top: -20px;
  padding-left: 10px;
  width: 5%;
  height: 5%;
}

.inputWithSideLabel {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.inputWithSideLabel2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 0px;
}

.inputWithSideLabel > div {
  margin: 10px 0px 10px 0px;
}

.inputWithSideLabel2 > div {
  margin: 0px 0px 10px 0px;
}

.sideLabel {
  margin-left: 10px;
  font-family: "Montserrat";
  font-size: 14px;
  color: #7d8d91;
  letter-spacing: 0;
}

.leftPaddedInputContainer {
  padding-left: 50px;
}

.error {
  color: red;
  margin-top: 7px;
  margin-bottom: 3px;
  font-size: 12px;
}

.sideLabelRight {
  margin-right: 10px;
}
.sideLabelLeft {
  margin-left: 10px;
}

.noWrap {
  white-space: nowrap;
}

.faint {
  display: inline;
  font-weight: normal;
}

.pathContainer {
  display: flex;
  flex: 1;
}

.pathBox {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 30px;
  border-radius: 4px;
  background-color: #ffffff;
  padding: 10px;
  font-size: 16px;
  color: #7d8d91;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  margin-right: 10px;
  margin-left: 10px;
  cursor: pointer;
  border: 1px solid #ebf0f4;
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.pathBoxActive {
  border: solid 2px #2d42ff;
}

.footerRight {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.listContainer {
  width: 100%;
}

.descriptionContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-content: center;
  /* width: 90%; */
  margin-bottom: 10px;
  padding: 25px;
  border-radius: 4px;
  background-color: #f3faff;
  color: #7d8d91;
}

.descriptionHeader {
  font-size: 16px;
  font-weight: normal;
}

.descriptionText {
  font-size: 14px;
  font-weight: normal;
  margin-bottom: 10px;
  line-height: 1.5;
}

/** Styles for Sub Dashboard List **/
.listTitle {
  font-family: "Montserrat";
  color: #7e8cae;
  letter-spacing: 0;
}

.listTitleBold {
  font-family: "Montserrat" - Bold;
  color: #7e8cae;
  letter-spacing: 0;
}

.listImageSideText {
  margin-left: 0.7rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.listBlockedOverflowText {
  overflow: hidden;
  white-space: wrap;
  text-overflow: ellipses;
  margin-bottom: 0.2rem;
}

.action {
  background: #ffffff;
  border: 1px solid #d5e1ea;
  border-radius: 3px;
  font-family: "Montserrat";
  font-size: 14px;
  color: #8190a8;
  letter-spacing: 0;
  cursor: not-allowed;
}

.tooltip {
  padding-left: 0.3rem;
  width: 20px;
}

.tooltipImage {
  max-width: 280px;
  margin: 0 auto;
}

.borderBox {
  box-sizing: border-box;
}

.gridRowNoPadding {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.inputLabelRight {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.infoLogoContainer {
  margin-left: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  cursor: pointer;
}

.styledInfoIcon {
  height: 1.5rem;
  width: 1.5rem;
}

.searchContainer {
  width: 100%;
  padding: 20px;
  padding-top: 0;
}

.searchBox {
  width: 100%;
}

.popupBNCN {
  font-family: "Montserrat";
  justify-content: center;
  text-align: center;

  p {
    margin-top: 5;
  }
}

.popupLink {
  cursor: pointer;
  text-decoration: underline;
  font-family: "Montserrat";
}

.spacingTopBottom {
  margin-top: 20px;
  margin-bottom: 20px;
}

.innerSectionRight {
  background: #f3f6fa;
}
