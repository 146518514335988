$accent-color: #38a6f4;

.radioGroup {
  max-width: inherit;
  margin-bottom: 30px;
  label {
    display: block;
    padding: 0.5em;
    cursor: pointer;

    > span:before {
      content: "";
      display: inline-block;
      vertical-align: -0.25em;
      height: 1.4em;
      width: 1.4em;
      border-radius: 50%;
      margin-right: 0.5em;
      background: #ffffff;
      border: 1px solid #dee5e6;
    }

    > input:checked + span:before {
      border-color: $accent-color;
      background-image: radial-gradient(
        circle closest-side,
        $accent-color 0%,
        $accent-color 50%,
        transparent 50%,
        transparent 100%
      );
    }

    > input:disabled + span {
      opacity: 0.5;
    }

    > input:disabled:checked + span:before {
      background-image: radial-gradient(
        circle closest-side,
        rgba(0, 0, 0, 0.5) 0%,
        rgba(0, 0, 0, 0.5) 50%,
        transparent 50%,
        transparent 100%
      );
    }

    > input {
      display: none;
    }
  }
}

.toggle-group {
  padding: 1em;

  &:before,
  &:after {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
  }

  label {
    float: left;
    cursor: pointer;

    > span {
      padding: 0.5em 1em;
      background-color: #fff;
      box-shadow: 0 1.5px 4px rgba(0, 0, 0, 0.12),
        0 1.5px 6px rgba(0, 0, 0, 0.06);

      &:focus,
      &:hover {
        color: $accent-color;
      }
    }

    &:not(:last-of-type) > span {
      border-right: 0;
    }

    &:first-of-type > span {
      border-radius: 2px 0 0 2px;
    }

    &:last-of-type > span {
      border-radius: 0 2px 2px 0;
    }

    > input:checked + span {
      background-color: $accent-color;
      color: white;
      font-weight: bold;
    }

    > input {
      display: none;
    }
  }
}

.container {
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  flex: 1;
  padding: 10px 0;
  margin-bottom: 6px;
  cursor: pointer;
}

.radioButtonArea {
  align-self: center;
}
.labelArea {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  color: #647e97 !important;
}

.labelStyle {
  font-family: Helvetica;
  font-size: 14px;
  letter-spacing: 0;
  color: #000000de !important;
}

.subLabelStyle {
  font-family: Helvetica;
  font-size: 13px;
  color: #7d8d91 !important;
  letter-spacing: 0;
}

.selectedContainer {
  display: flex;
  padding: 10px 0;
  border: solid 0.5px #b6d6ec;
  background-color: #f3faff;
}

.noPaddingMarginContainer {
  margin: 0px;
  padding: 0px;
}

.radioLabel {
  font-family: Helvetica-Bold;
  font-size: 12px;
  color: #7d8d91;
  letter-spacing: 0;
}

.radioList {
  margin-bottom: 30px;
  label {
    display: block;
    padding: 0.5em;
    cursor: pointer;

    > span:before {
      content: "";
      display: inline-block;
      vertical-align: -0.25em;
      height: 1.4em;
      width: 1.4em;
      border-radius: 50%;
      margin-right: 0.5em;
      background: #ffffff;
      border: 1px solid #dee5e6;
    }

    > input:checked + span:before {
      border-color: $accent-color;
      background-image: radial-gradient(
        circle closest-side,
        $accent-color 0%,
        $accent-color 50%,
        transparent 50%,
        transparent 100%
      );
    }

    > input:disabled + span {
      opacity: 0.5;
    }

    > input:disabled:checked + span:before {
      background-image: radial-gradient(
        circle closest-side,
        rgba(0, 0, 0, 0.5) 0%,
        rgba(0, 0, 0, 0.5) 50%,
        transparent 50%,
        transparent 100%
      );
    }

    > input {
      display: none;
    }
  }
}

.horizontal {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 0px;
}

.thinVerticalSpacing > div {
  padding-top: 0px;
  padding-bottom: 0px;
}

.noLargeSpacingMargins {
  margin-bottom: 0px;
}

.padRight {
  padding-right: 20px;
}

.thinMarginBottom {
  margin-bottom: 0px !important;
}

.marginBottom {
  display: flex;
  margin: 1.5rem 0rem;
}
