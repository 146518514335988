.container {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-content: center;
  height: 30px;
  margin-bottom: 10px;
}

.darkBox {
  flex: 1;
  padding: 10px;
  padding-bottom: 20px;
  border-radius: 4px;
  height: 100%;
  background-color: #f7f8f8;
}
.darkBoxNoMarginTop {
  flex: 1;
  padding: 10px;
  padding-bottom: 15px;
  border-radius: 4px;
  background-color: #f7f8f8;
  margin-bottom: 20px;
}
.round {
  position: relative;
  margin-right: 10px;
  margin: auto 5px;
}

.round label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 3px;
  cursor: pointer;
  height: 24px;
  width: 24px;
  left: 0;
  position: absolute;
  top: 0;
}

.round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 5px;
  opacity: 0;
  position: absolute;
  top: 6px;
  transform: rotate(-45deg);
  width: 12px;
}

.round input[type="checkbox"] {
  visibility: hidden;
}

.round input[type="checkbox"]:checked + label {
  background-color: #38a6f4;
  border-color: #38a6f4;
}

.round input[type="checkbox"]:checked + label:after {
  opacity: 1;
}

body {
  background-color: #f1f2f3;
}

.labelStyle {
  align-self: center;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  // color: #7d8d91;
  color: #647e97;
  margin: auto 15px;
  padding-top: 10px;
}

.nowrap {
  white-space: nowrap;
}

.connectedContainer {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  height: 30px;
  margin-bottom: 10px;
}

.connectedContainer > .round {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 3px;
  cursor: pointer;
  height: 24px;
  width: 24px;
  left: 0;
  position: absolute;
  top: 0;
}

.connectedInput {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 3px;
  cursor: pointer;
  height: 24px;
  width: 24px;
}
.connectedContainer > .labelStyle {
  padding-top: 0px;
}

.connectedContainer input[type="checkbox"]:checked + label {
  background-color: #38a6f4;
  border-color: #38a6f4;
}

.connectedContainer input[type="checkbox"]:checked + label:after {
  opacity: 1;
}
